import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Otp = () => {
    const navigate = useNavigate();

   
    

    const [loginInput,setLogin] = useState({
        email:'',
        error_list:[]
    });
    const handleInput =(e)=>{
        
        e.persist();
        setLogin({...loginInput,[e.target.name]:e.target.value.replace(/\D/g, "")});
        console.log(loginInput);   
    }
    const [loading, setLoading] = useState(false);
    const otpSubmit=(e)=>{
           e.preventDefault();
           const email = localStorage.getItem("email");
           const data = {
            email:email,
            otp:loginInput.otp

           }
           
            axios.post('/otp',data).then(res=>{
                console.log("response"+res);
            if(res.data.status===200)
            {
                localStorage.setItem("auth_token",res.data.token);
                localStorage.setItem("auth_name",res.data.username);
                localStorage.setItem("email",res.data.email);
                localStorage.setItem("auth_user",JSON.stringify(res.data.user));
                console.log(JSON.stringify(res.data.user));
                navigate('/');
            }else if(res.status===400){
                alert('Invalid OTP');
            } else{
                setLogin({...loginInput, error_list: res.data.validation_errors });
            }

           }).catch((err)=>{
                toast.error('Invalid OTP..Try Again');
           });
    }


  return (
    <>  <body className="hold-transition login-page">
        <div className="login-box">
            <div className="login-logo">
                <a href="../../index2.html"><b>Wiz</b>AR</a>
            </div>
              
            <div className="card">
                <div className="card-body login-card-body">
                <p className="login-box-msg">ENTER OTP</p>
                <ToastContainer />
                <form onSubmit={otpSubmit}>
                    <div className="input-group mb-3">
                    <input type="text"  maxLength={6} name="otp" onChange={handleInput} value={loginInput.otp} className="form-control" placeholder="Enter Six Digit OTP"/>
                    <div className="input-group-append">
                        <div className="input-group-text">
                        <span className="fas fa-gears"></span>
                        </div>
                    </div>
                    
                    </div>
                    <span>{loginInput.error_list.email}</span>
                    {/* <div className="input-group mb-3">
                    <input type="password" onChange={handleInput} value="" className="form-control" placeholder="Password"/>
                    <div className="input-group-append">
                        <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                        </div>
                    </div>
                    </div> */}
                    <div className="row">
                    <div className="col-4">
                        <button type="submit" className="btn btn-primary btn-block">Verify</button>
                    </div>
                    
                    </div>
                </form>
                
                </div>
                
            </div>
        </div>
        </body>
    </>
  )
}

export default Otp