import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDefaultNormalizer } from '@testing-library/react';

const Products = () => {

  const [products,setProducts] = useState([]);
  const [pagination,setPagination] = useState('');
  const [currPage,setCurrPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const countPerPage = 1;


  const [loading, setLoading] = useState(false);

  const [inputs,setInputs] = useState({});
  const [sortField,setSortField] = useState();
  const [productname,setProductname] = useState(null);
  const [status,setStatus]     = useState(null);
  
    const setProductNameChange = (e)=>{
      console.log("name"+e.target.value);
      setProductname(e.target.value);

    }
    const setStatusChange = (e)=>{
      console.log("name"+e.target.value);
      setStatus(e.target.value);
    }
  const submitForm = (e) =>{
    setLoading(true);
    setPagination('');
    setPage(1);
    e.preventDefault();
    fetchProducts({page,countPerPage,productname,status});
  } 
  const clearForm=()=>{
     window.location.reload();
  }
  useEffect(()=>{
    fetchProducts({page,countPerPage,productname,status});
  },[]);


  const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
  const config = {
    headers: {
      Authorization: bearer_token
    }
  };
  const fetchProducts= ({page,countPerPage,productname,status})=>{
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    const productURL = `/products?page=${page}&per_page=${countPerPage}&product_name=${productname}&status=${status}`;
    setLoading(true);
    axios.get(productURL,config).then(res=>{
      if(res.status===200)
      {
        setProducts(res.data.data);
        setPagination(res.data.links);
        setCurrPage(res.data.current_page);
        setLoading(false);
        setPage(res.data.from);  
        setPageTotal(res.data.total);  
      }
      if(res.status===203)
      {
        toast.error(res.data.message);
        setLoading(false);  
      }
     }).catch((err)=>{
      toast.error(err);
      setLoading(false);      
    });
  }
  

  const getPreviousRecords=({id})=>{
     
    //fetchProducts({page,countPerPage,productname,status});
  }

  const getNextRecords=(id)=>{
    let page = id;
    fetchProducts({page,countPerPage,productname,status});
  }
  
 
  const deleteProduct=(id)=>{
   // console.log("testing"+id);
  }
  
  const [order, setOrder] = useState("ASC");
  function applySorting(col) {
    console.log("sorting"+col);
    if(order==="ASC")
    { 
      const sorted = [...products].sort((a,b)=>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setProducts(sorted);
      setOrder("DSC");
      
    }
    if(order==="DSC")
    {
      const sorted = [...products].sort((a,b)=>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setProducts(sorted);
      setOrder("ASC");
    }
    setSortField(col);


  }

  return (
    <>
        <Navbar/>
        <Sidebar></Sidebar>
        <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Products</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><Link href="/">Home</Link></li>
                    <li className="breadcrumb-item active">Products</li>
                    </ol>
                </div>
                </div>
            </div>
        </div>
        <section className='content'>
            <div className="container-fluid">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Search </h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-4">
                      <input type="text" name='name' onChange={setProductNameChange} value={ productname || ''} className="form-control" placeholder="Enter Product Name"/>
                    </div>
                    <div className="col-4">
                      <select name="status"  value={inputs.status} onChange={setStatusChange} className='form-control' >
                        <option value="select"  >Select Product Status</option>
                        <option value="1" selected={ inputs.status===1 ?'selected'  :''} > Is Requested</option>
                        <option value="2" selected={ inputs.status===2 ? 'selected'  :''}>Is Rejected</option>
                        <option value="3" selected={ inputs.status===3 ? 'selected'  :''}>In progress</option>
                        <option value="4" selected={ inputs.status===4 ? 'selected'  :''}>Is Review</option>
                      </select>
                    </div>
                    <div className="col-2 d-flex">
                      <button type="button" onClick={submitForm}  className='btn btn-sm btn-primary mr-1 '>Search</button>
                      { productname || status ? 
                      <button type="button" onClick={clearForm}  className='btn btn-sm btn-danger mr-1 '>Clear</button> : ''}
                    </div>
                    <div className="col-1"></div>    
                  </div>
                </div>
              </div>
            </div>
        </section>        
        <section className="content" style={{"height":"570px"}}>
          <ToastContainer />
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-12'>
                      <div className="card card-dark">
                        <div className="card-header">
                          <h3 className="card-title">Products Listing</h3>
                        </div>
                        
                        <div className="card-body">
                          <table id="example1" className="table table-bordered table-striped">
                            <thead>
                            <tr>
                              <th>Id</th>
                              <th onClick={() => applySorting("product_name")}>Name { sortField==="product_name"  ? order==="ASC" ? <i class="fas fa-sort-up"></i> : <i class="fas fa-sort-down"></i>:'' }</th>
                              <th onClick={() => applySorting("status")}>Status { sortField==="status"  ? order==="ASC" ? <i class="fas fa-sort-up"></i> : <i class="fas fa-sort-down"></i>:'' }</th>
                              <th>Frontend URL</th>
                              <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            
                            {  
                              products && products.length > 0  ? 
                              products.map((product, index) =>  (
                                
                                <tr key={index}>
                                <td >{product.id}</td>
                                <td >{product.product_name} </td>
                                <td > 
                                   { product.status==="5" ? <span className='badge bg-success'>Is Published</span> :''} 
                                   { product.status==="4" ? <span className='badge bg-default'>Is Review</span> :''}
                                   { product.status==="3" ? <span className='badge bg-primary'>In Progess</span> :''}
                                   { product.status==="2" ? <span className='badge bg-danger'>Is Rejected</span> :''}
                                   { product.status==="1" ? <span className='badge bg-warning'>Is Requested</span>:''}
                                </td>
                                <td >{product.frontend_url}</td>
                                <td>                                    
                                    <Link className='btn btn-sm btn-primary' to={{ pathname:'/products/edit/'+product.id}}><i className='fas fa-edit' ></i></Link> 
                                    <Link className='btn btn-sm btn-info ml-1' to={{ pathname:'/products/view/'+product.id}}><i className='fas fa-eye' ></i></Link> 
                                    <button className='btn btn-sm btn-danger ml-1' onClick={() => { if (window.confirm('Are you sure you want to logout ?')) { deleteProduct(product.id); console.log("Delete Product") }; }}><i className='fas fa-trash'></i> </button> 
                                </td>
                              </tr>
                              )) :   
                                <tr>
                                  <td className="text-center" colSpan={5}>NO Records found</td>
                                </tr>
                            }
                            </tbody>
                          </table>
                        </div>
                        { loading  ? 
                        <div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>          
                        : ''}
                        <div className="card-footer clearfix">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination justify-content-end">
                            {pagination ? pagination.map((pagi,index)=>(
                              <>
                                <li className={ pagi.url===null ? 'page-item disabled' :  pagi.active ?  'page-item active'  : 'page-item '}>
                                    { pagi.url===null ?
                                          <span class="page-link" onClick={() => {
        getPreviousRecords(pagi.label)
    }} >{pagi.label}</span> 
                                          : pagi.active===true ? 
                                              <span class="page-link active">
                                              {pagi.label} 
                                               <span class="sr-only">(current)</span>
                                              </span> : 
                                            <button class="page-link"  onClick={() => {
        getNextRecords(pagi.label)
    }}    >{pagi.label}</button> 
                                    }
                                </li>
                              </>
                            )) :'' }
                          </ul>
                        </nav>
                        { pagination ? 
                         <> <p className='float-right'>showing {page} of {pageTotal}</p></> 
                          :''
                         }   
                        </div>   
                      </div>
                    </div>
                </div>
            </div>
        </section> 
                
        </div>
        <Footer></Footer>
     </>
  )
}

export default Products