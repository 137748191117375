import React from 'react'

const Success = () => {
  return (
    <div>
        <center>
        <h3>You have successfully subscribed , Please check you mail to proceed futher.</h3>
        <h5>Thanks</h5>
        </center>
    </div>
  )
}

export default Success