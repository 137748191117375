import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import {  Link } from 'react-router-dom';
import { useParams  } from 'react-router-dom';

const ProductEdit = (props) => {
    const {id} = useParams();
    const [inputs,setInputs] = useState({});
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
    const config = {
      headers: {
        Authorization: bearer_token
      }
    };
    useEffect(()=>{
        fetchProduct();
    },[]);


    const fetchProduct = () =>{
        setLoading(true);
        axios.get('/products/'+id+'/edit',config).then((res)=>{
           
            setInputs({
                product_name:res.data.product_name,
                frontend_url:res.data.frontend_url,
                feedback:res.data.feedback,
                status:res.data.status,
                product_images:res.data.product_images,
                product_models:res.data.product_models,
            });
            setLoading(false);
        });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
            
        
        if(event.target.value==="2")
        {
            document.getElementById('feedback').style.display="block";
        }else{
            document.getElementById('feedback').style.display="none";
        }
       
        setInputs(values => ({...values,[name]:value}))
    }

    const [feedback, setFeedback] = useState('');
    const handleFeedbackChange =(event)=>{
        setFeedback(event.target.value);
    }  
    
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    const submitForm = (e) =>{
        console.log("feedback is"+feedback);
        setLoading(true);
        e.preventDefault();
        let formData = new FormData();
        console.log("files"+JSON.stringify(files));
        console.log('inputs',JSON.stringify(inputs));
            Array.from(files).forEach(item=>{
                console.log("item"+item);
                formData.append("filedata[]",item);
            });
            formData.append('inputs',JSON.stringify(inputs));
            formData.append('feedbackdetail',feedback);
            formData.append('userid',auth_user.id);
            formData.append('id',id);
            
          console.log("posting data"+JSON.stringify(formData));
            axios.post('/uploadModal',formData).then((res)=>{
                setLoading(false);
                //window.location.reload();
                //navigate('/');
            })
    }
    

  return (
    <>
        <Navbar/>
        <Sidebar></Sidebar>
        <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Products</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><Link href="/">Home</Link></li>
                    <li className="breadcrumb-item active">Product Edit</li>
                    </ol>
                </div>
                </div>
            </div>
        </div>        
        <section className="content">
          

            <div className="container-fluid">
                <form onSubmit={submitForm} enctype="multipart/form-data"> 
                <div className='row'>
                    <div className='col-12'>
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Product Edit</h3>
                          <Link to="/products" className='btn btn-sm btn-primary float-right'>Back</Link>
                        </div>
                
                        <div className="card-body">
                            <div className='row'>
                                
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label htmlFor="inputEstimatedBudget">Product Name</label>
                                        <input type="text" name='product_name'  id="inputEstimatedBudget" className="form-control" value={inputs.product_name || ''}
                                onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <label htmlFor="inputEstimatedDuration">Current Status</label>
                                        <select id='current-status' value={inputs.status} name='status' onChange={handleChange} className='form-control'>
                                            <option value='select'>Select</option>
                                            <option value="1" selected >Is Requested</option> 
                                            <option value="2" selected>Is Rejected</option> 
                                            <option value="3" selected>In Progress</option> 
                                            <option value="4" selected>Is Reviewed</option> 
                                        </select>
                                    </div>
                                    {/* <div className='form-group'>
                                        <label>Created Date</label>
                                        <span>date</span>
                                    </div> */}
                                </div>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label htmlFor="inputSpentBudget">Frontend URL</label>
                                        <input type="text" name='frontend_url' id="inputSpentBudget" className="form-control" value={inputs.frontend_url || ''}
                                        onChange={handleChange}/>
                                    </div>
                                </div>
                                
                                <div className='col-md-12'>
                                    <div id="feedback">
                                    { inputs.status==="2" ?
                                        <div className='form-group'>
                                            <label>Feedback</label>
                                            <textarea name='feedback' className='form-control' value={feedback} onChange={handleFeedbackChange}></textarea>
                                        </div>
                                        : ''}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-md-12'>
                                 
                                <h4 className='ml-3'>Product Images</h4>
                                <div className="col-md-12 card-deck">
                                { (inputs.product_images && inputs.product_images !=="") ? 
                                  inputs.product_images.map((images,index)=>{
                                    return(
                                        
                                        <div className="col-md-3 card" key={index}>
                                            <img className="card-img-top border " src={images.url} alt=""/>
                                            <div className="card-body text-center p-2">
                                                <h5 className="card-title float-none">{images.file_name}</h5>
                                                {/* <p className="card-text "><button className='btn btn-xs btn-danger'>Remove</button></p> */}
                                            </div>
                                        </div>
                                    
                                    )
                                  })
                                                                 
                                 : ''}
                                 </div> 
                            </div>
                        </div>
                        <hr/>
                        <div className='row mb-3'>
                            
                            {/* <div className='col-md-3'>
                                <label className='label ml-2'>Upload glb & Usdz Modal</label>
                            </div> */}
                            <div className='col-md-6 ml-3'>
                                <label for="exampleFormControlFile1">Upload glb & Usdz Modal</label>
                                <input type="file" onChange={(e)=>{
                                    setFiles(e.target.files)
                                }} multiple />
                                
                            </div>
                            
                            <div className='col-md-12'>
                            <hr/>
                                <h4 className='ml-3'>Product Models</h4>
                                <div className="col-md-12 card-deck">
                                { (inputs.product_models && inputs.product_models !=="") ? 
                                  inputs.product_models.map((models,index)=>{
                                    
                                    return(                                        
                                        <div className="col-md-3 card" key={index}>
                                            <div className="card-body text-center p-2">
                                                <h5 className="card-title float-none">{models.model_name}</h5>
                                                
                                                <p className="card-text ">
                                                    <a className='btn btn-xs btn-warning mr-2' href={models.models.model_url}>Download</a>
                                                    <button className='btn btn-xs btn-danger'>Remove</button>
                                                </p>
                                            </div>
                                        </div>
                                    
                                    )
                                  })
                                                                 
                                 : ''}
                                 </div>  
                            </div>
                        </div>
                        { loading  ? 
                        <div class="overlay"><i class="fas fa-2x fa-sync-alt fa-spin"></i></div>          
                        : ''}
                        { loading  ? 'Please Wait Loading' :
                        <div className="card-footer clearfix">
                                   
                          <button type="submit"  className='btn btn-sm btn-success'>Save Changes</button>
                          <button className='btn btn-sm btn-default ml-2'>Cancel</button>
                          
                        </div>
                        }   
                      
                      </div>
                      
                    </div>
                </div>
                </form>
            </div>
            
        </section>
        
        </div>
    </>
  )
}

export default ProductEdit