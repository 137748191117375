import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from "./layouts/admin/Login";
import Payment from "./layouts/admin/Payment";
import Plans from "./layouts/admin/Plans";
import Success from "./layouts/admin/Success";
import Otp from "./layouts/admin/Otp";
import VerifyEmail from "./layouts/admin/VerifyEmail";
import axios from "axios";
import Dashboard from "./layouts/admin/Dashboard";
import Profile from "./layouts/admin/Profile";
import PrivateRoute from "./layouts/admin/PrivateRoute";
import Settings from "./layouts/admin/Settings";
import Products from "./layouts/admin/Products";
import ProductEdit from "./layouts/admin/ProductEdit";
import ProductView from "./layouts/admin/ProductView";
import Changepassword from "./layouts/admin/Changepassword";
import UserPayment from "./layouts/admin/UserPayment";
import Requested from "./layouts/admin/Requests";
import Customers from "./layouts/admin/Customers";
import Requests from "./layouts/admin/Requests";
import CustomerEdit from "./layouts/admin/CustomerEdit";
import CustomerView from "./layouts/admin/CustomerView";
import Test from "./layouts/admin/UserpaymentView";
import UserpaymentView from "./layouts/admin/UserpaymentView";
import RequestsEdit from "./layouts/admin/RequestsEdit";
import RequestsView from "./layouts/admin/RequestsView";

//axios.defaults.baseURL="http://127.0.0.1:8000/api/v1";
axios.defaults.baseURL="https://wizar-dev-backend.theintellify.net/api/v1";
//axios.defaults.headers.post['Content-type']='application/json';
//axios.defaults.headers.post['Accept']='application/json';
axios.defaults.headers.post['Content-Type']='multipart/form-data';
 
function App() {
  return (
    <div className="App" key={'wizar'}>
       
     <Router>
              
                
            <Routes>
            
            <Route exact path="/profile" element={<PrivateRoute><Profile/></PrivateRoute>}/>
            <Route exact path="/settings" element={<PrivateRoute><Settings/></PrivateRoute>}/>
            <Route exact path="/login" element={<Login/>}/>
            <Route exact path="/plans" element={<Plans/>}/>
            <Route exact path="/otp" element={<PrivateRoute><Otp/></PrivateRoute>}/>
            <Route exact path="/products" element={<PrivateRoute><Products/></PrivateRoute>}/>
            <Route exact path="/products/edit/:id" element={<PrivateRoute><ProductEdit/></PrivateRoute>}/>
            <Route exact path="/products/view/:id" element={<PrivateRoute><ProductView/></PrivateRoute>}/>
            <Route exact path="/changepassword" element={<PrivateRoute><Changepassword/></PrivateRoute>}/>
            <Route exact path="/userpayments" element={<PrivateRoute><UserPayment/></PrivateRoute>}/>
            <Route exact path="/success" element={<Success/>}/>
            <Route exact path="/verify" element={<VerifyEmail/>}/>
            <Route exact path="/payment" element={<Payment/>} />
            <Route exact path="/" element={<PrivateRoute><Dashboard/></PrivateRoute>}/> 
            <Route exact path="/requests" element={<Requests/>}/> 
            <Route exact path="/customers" element={<Customers/>}/>
            <Route exact path="/userpayments/:id" element={<UserpaymentView/>}/>
            <Route exact path="/customers/edit/:id" element={<PrivateRoute><CustomerEdit/></PrivateRoute>}/>
            <Route exact path="/customers/view/:id" element={<PrivateRoute><CustomerView/></PrivateRoute>}/> 

            <Route exact path="/requests/edit/:id" element={<PrivateRoute><RequestsEdit/></PrivateRoute>}/>
            <Route exact path="/requests/view/:id" element={<PrivateRoute><RequestsView/></PrivateRoute>}/> 

            </Routes>
        
     </Router>

          
         
       
    </div>
  );
}

export default App;
