import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import Footer from './Footer';

const Requests = () => {

  const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
    const config = {
      headers: {
        Authorization: bearer_token
      }
    };

  
  

  const [ productrequest,setProductRequest] = useState([]);
  const [pagination,setPagination] = useState('');
  const [currPage,setCurrPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const countPerPage = 1;


  const [loading, setLoading] = useState(false);
  const [inputs,setInputs] = useState({});
  const [sortField,setSortField] = useState();
  const [productname,setProductname] = useState(null);
  const [customername,setCustomername] = useState(null);
  const [createdAt,setCreatedAt]  = useState(null);

  const setCustomerChange = (e)=>{
    setCustomername(e.target.value);

  }
  const setProductNameChange = (e)=>{
    setProductname(e.target.value);
  }
  const setCreatedChange = (e)=>{
    console.log("Setting created"+e.target.value);
    setCreatedAt(e.target.value);
  }

  const submitForm = (e) =>{
    setLoading(true);
    setPagination('');
    setPage(1);
    e.preventDefault();
    fetchRequestedProduct({page,countPerPage,customername,productname,createdAt});
  } 
  const clearForm=()=>{
     window.location.reload();
  }
  const getPreviousRecords=({id})=>{
     
    //fetchRequestedProduct({page,countPerPage,customername,productname,createdAt});
  }

  const getNextRecords=(id)=>{
    let page = id;
    fetchRequestedProduct({page,countPerPage,customername,productname,createdAt});
  }
  

    const fetchRequestedProduct=()=>{
      console.log("CREATED AT"+createdAt);
        const auth_user = JSON.parse(localStorage.getItem("auth_user"));
        const url = `/requests?page=${page}&per_page=${countPerPage}&customername=${customername}&productname=${productname}&createdAt=${createdAt}`;
        setLoading(true); 
        axios.get(url,config).then(res=>{
          if(res.status===200)
          {           
            //console.log("product Request data"+JSON.stringify(res.data.data));
            setProductRequest(res.data.data);  
            setPagination(res.data.links);
        setCurrPage(res.data.current_page);
        setLoading(false);
        setPage(res.data.from);  
        setPageTotal(res.data.total);  

          } 
        });
    }
    useEffect(()=>{
      fetchRequestedProduct();
    },[])


    

  return (
    <>
        <Navbar />
      <Sidebar></Sidebar>
      <div className="content-wrapper" >
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Requests</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item active">Requests</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className='content'>
            <div className="container-fluid">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Search </h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-3">
                      <input type="text" name='customername' onChange={setCustomerChange} value={ customername || ''} className="form-control" placeholder="Enter Customer Name"/>
                    </div>
                    <div className="col-3">
                    <input type="text" name='productname' onChange={setProductNameChange} value={ productname || ''} className="form-control" placeholder="Enter Product Name"/>
                    </div>
                    <div className="col-3">
                        
                        {/* <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                          <input type="text" name='createdAt' onSelect={setCreatedChange} value={ createdAt  } className="form-control float-right" id="reservation"/>
                        </div> */}
                        
                   
                        
                    </div>
                    <div className="col-2 d-flex">
                      <button type="button" onClick={submitForm}  className='btn btn-sm btn-primary mr-1 '>Search</button>
                      { productname || customername || createdAt ? 
                      <button type="button" onClick={clearForm}  className='btn btn-sm btn-danger mr-1 '>Clear</button> : ''}
                    </div>
                     
                  </div>
                </div>
              </div>
            </div>
        </section>


        <section className="content" style={{"height":"570px"}}>
              <div className="container-fluid">
                  <div className='row'>
                      <div className='col-12'>
                        <div className="card card-dark">
                          <div className="card-header">
                            <h3 className="card-title">Requests Listing</h3>
                          </div>
                  
                          <div className="card-body">
                            <table id="example1" className="table table-bordered table-striped">
                              <thead>
                              <tr>
                                
                                <th>Id</th>
                                <th>Customer Name</th>
                                <th>Product Name</th>
                                <th>Frontend URL</th>
                                <th>Status</th>
                                <th>Date</th>                                
                                <th>Actions</th>
                              </tr>
                              </thead>
                              <tbody>
                                {   productrequest.map((productReqst,index)=>(
                                    <tr key={index}>
                                      <td>{index+1}</td>
                                      
                                      <td>{productReqst.users!==null  ? productReqst.users.name :''  }</td>
                                      <td>{productReqst.product_name}</td>
                                      <td>{productReqst.frontend_url}</td>
                                      <td><span className='badge bg-warning'>Is Requested</span></td>
                                      <td>{new Date( productReqst.created_at ).toLocaleDateString()}</td>
                                      <td>                                      
                                          <Link className='btn btn-sm btn-primary ml-1' to={{ pathname:'/requests/edit/'+productReqst.id}}><i className='fas fa-edit' ></i></Link>
                                          <Link className='btn btn-sm btn-info ml-1' to={{ pathname:'/requests/view/'+productReqst.id}}><i className='fas fa-eye' ></i></Link> 
                                          <button type='button' className='btn btn-sm btn-danger ml-1' to={{ pathname:'/requests/delete/'+productReqst.id}} onClick={() => { if (window.confirm('Are you sure you want to delete these record ?')) { console.log("user logged out") }; }}><i className='fas fa-trash' ></i></button>  
                                      </td>
                                    </tr>
                                   ))
                                  }                                 
                              </tbody>
                            </table>
                          </div>
                          { loading  ? 
                        <div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>          
                        : ''}
                          <div className="card-footer clearfix">
                          <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-end">
                            {pagination ? pagination.map((pagi,index)=>(
                              <>
                                <li className={ pagi.url===null ? 'page-item disabled' :  pagi.active ?  'page-item active'  : 'page-item '}>
                                    { pagi.url===null ?
                                          <span className="page-link" onClick={() => {
        getPreviousRecords(pagi.label)
    }} >{pagi.label}</span> 
                                          : pagi.active===true ? 
                                              <span className="page-link active">
                                              {pagi.label} 
                                               <span className="sr-only">(current)</span>
                                              </span> : 
                                            <button className="page-link"  onClick={() => {
        getNextRecords(pagi.label)
    }}    >{pagi.label}</button> 
                                    }
                                </li>
                              </>
                            )) :'' }
                          </ul>
                        </nav>
                        { pagination ? 
                         <> <p className='float-right'>showing {page} of {pageTotal}</p></> 
                          :''
                         }   
                          </div>   
                        </div>
                      </div>
                  </div>
              </div>
        </section>
      </div>
       <Footer></Footer>
     </>
  )
}

export default Requests