import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import axios, { formToJSON } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 


const Customers = () => { 

  const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
  
  const config = {
    headers: {
      Authorization: bearer_token
    }
  };

 const [currentPage,setCurrentPage]=useState(1);
  
  //Columns required for table
  //set the state of  customer 
  const [customers,setCustomers] = useState([]);
  const [pagination,setPagination] = useState('');
  const [currPage,setCurrPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const countPerPage = 4;
  //For search fields
  const [inputs,setInputs] = useState({});
  const [sortField,setSortField] = useState();
  
  const [username,setUsername] = useState(null);
  const [email,setEmail]       = useState(null);
  const [status,setStatus]     = useState(null);

  useEffect(()=>{
    fetchCustomers({page,countPerPage,username,email,status});
  },[]);

   
  const fetchCustomers=({page,countPerPage,username,email,status})=>{
    
    const customerURL = `/customers?page=${page}&per_page=${countPerPage}&name=${username}&email=${email}&status=${status}`;
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    const bodyParameters = {
      user: auth_user
    };
    //const customerURL = '/customers';  
    setLoading(true);
    axios.get(customerURL,config).then(res=>{
      
      if(res.status===200)
      {
        setCustomers(res.data.data);
        setPagination(res.data.links);
        setCurrPage(res.data.current_page);
        setLoading(false);
        setPage(res.data.from);  
        setPageTotal(res.data.total);
      }
      if(res.status===204)
      {
        toast.error("No Record Found");
        setLoading(false);
      }      
    }).catch((err)=>{
      //alert("Unauthorised Access");
    });
  }
  //console.log("customers"+JSON.stringify(customers));
 

  

  const updateStatus=(id)=>{
    console.log("id is "+id);
    const bodyParameters = {
      id: id
    };
    const customerUpdateURL = '/customers/updatestatus';
    setLoading(true);
    axios.post(customerUpdateURL,bodyParameters,config).then(res=>{
      if(res.status===200)
      {
        toast.success("Status updated successfully");
        //setCustomers(res.data.data);
        setLoading(false);  
        window.location.reload();
       
      }      
    }).catch((err)=>{
      toast.error('Unauthorised access');
      //alert("Unauthorised Access");
    });
  }

  const [order, setOrder] = useState("ASC");
  function applySorting(col) {
    console.log("sorting"+col);
    if(order==="ASC")
    { 
      const sorted = [...customers].sort((a,b)=>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setCustomers(sorted);
      setOrder("DSC");
      
    }
    if(order==="DSC")
    {
      const sorted = [...customers].sort((a,b)=>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setCustomers(sorted);
      setOrder("ASC");
    }
    setSortField(col);


  }

  //console.log("pagination"+JSON.stringify(pagination));
  
  // const handleChange = (event) => {
  //   // const name = event.target.name;
  //   // const value = event.target.value;
  //   // setInputs(values => ({...values,[name]:value}))
    
  // }  
  
  //Pagination function goes here

  const getPreviousRecords=({id})=>{
    console.log("previous"+id);
  }

  const getNextRecords=(id)=>{
    let page = id;

    fetchCustomers({page,countPerPage,username,email,status});
    console.log("click the page"+id);


  }
   


  const setNameChange = (e)=>{
    console.log("name"+e.target.value);
    setUsername(e.target.value);

  }
  const setEmailChange = (e)=>{
    console.log("name"+e.target.value);
    setEmail(e.target.value);
  }
  const setStatusChange = (e)=>{
    console.log("name"+e.target.value);
    setStatus(e.target.value);
  }


  const submitForm = (e) =>{
    setLoading(true);
    setPagination('');
    setPage(1);
    e.preventDefault();
    fetchCustomers({page,countPerPage,username,email,status});
  }  


  const clearForm=()=>{
    //setInputs({ name :null,email:null,status:null});  
    // setUsername(null);
    // setEmail(null);
    // setStatus(null);
    // fetchCustomers({page,countPerPage,username,email,status});
    window.location.reload();
    console.log("INOUTS"+inputs);     
      
  }
  
  return (
    <>
      <Navbar/>
        <Sidebar></Sidebar>
        <div className="content-wrapper" >
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Customers</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><Link href="/">Home</Link></li>
                    <li className="breadcrumb-item active">Customers</li>
                    </ol>
                </div>
                </div>
            </div>
        </div>
        <section className='content'>
            <div className="container-fluid">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Search </h3>
                </div>
                <div className="card-body">
                 
                  <div className="row">
                    <div className="col-3">
                      <input type="text" name='name' onChange={setNameChange} value={ username || ''} className="form-control" placeholder="Enter Name"/>
                    </div>
                    <div className="col-4">
                      <input type="email"  name='email' onChange={setEmailChange} className="form-control" placeholder="Enter Email" value={ email || ''} />
                    </div>
                    <div className="col-3">
                      <select name="user_status"  value={inputs.user_status} onChange={setStatusChange} className='form-control' >
                        <option value="select"  >Select Status</option>
                        <option value="1" selected={ inputs.user_status===1 ?'selected'  :''} >Active</option>
                        <option value="0" selected={ inputs.user_status===0 ? 'selected'  :''}>Inactive</option>
                      </select>
                    </div>
                    <div className="col-2 d-flex">
                      <button type="button" onClick={submitForm}  className='btn btn-sm btn-primary mr-1 '>Search</button>
                      { username || status ||  email ? 
                      <button type="button" onClick={clearForm}  className='btn btn-sm btn-danger mr-1 '>Clear</button> : ''}
                    </div>
 
                  </div>
                 
                </div>
                
              </div>
            </div>
        </section>


        <section className="content" style={{"height":"570px"}}>
        
            <ToastContainer />
            <div className="container-fluid" style={{"position": 'relative', 'minheight': '200px'}}>
                <div className='row'>
                    <div className='col-12'>
                      <div className="card card-dark">
                        <div className="card-header">
                          <h3 className="card-title">Customer Listing</h3>
                        </div>

                        <div className="card-body">
                        <table id="example1" className="table table-bordered table-striped">
                            <thead>
                            <tr>
                              <th onClick={() => applySorting("id")}>Id </th>
                              <th onClick={() => applySorting("name")} >Name  { sortField==="name"  ? order==="ASC" ? <i class="fas fa-sort-up"></i> : <i class="fas fa-sort-down"></i>:'' }</th>
                              <th onClick={() => applySorting("email")}>Email  { sortField==="email"  ?   order==="ASC" ? <i class="fas fa-sort-up"></i> : <i class="fas fa-sort-down"></i>  :'' } </th>
                              <th >Total Requested</th>
                              <th>Total Published</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>

                            { customers.length!=0 ? customers.map((customer,index)=>( 
                              <tr key={index}>
                                <td >{index+1}</td>
                                <td >{customer.name}</td>
                                <td > 
                                   {customer.email}
                                </td>
                                <td >{customer.TotalRequested}</td>
                                <td >{customer.TotalPublised}</td>
                                <td> 
                                { customer.user_status===1 ?   
                                
                                <BootstrapSwitchButton   onChange={() => {
        updateStatus(customer.id)
    }}  onlabel="Active"    checked offlabel="Inactive"  onstyle="success" offstyle="danger" size="xs" width="80" /> 
                                : ' '}

                              { customer.user_status===0 ?

                                <BootstrapSwitchButton    onChange={() => {
        updateStatus(customer.id)
    }} onlabel="Active"  offlabel="Inactive"   onstyle="success" offstyle="danger" size="xs" width="80" />
    :'' 
                                }
                                </td>
                                <td >                                    
                                    {/* <Link className='btn btn-sm btn-primary' to={{ pathname:'/customers/edit/'+1}}><i className='fas fa-edit' ></i></Link>  */}
                                    <Link className='btn btn-sm btn-info ml-1' to={{ pathname:'/customers/view/'+customer.id}}><i className='fas fa-eye' ></i></Link> 
                                    {/* <button className='btn btn-sm btn-danger ml-1' ><i className='fas fa-trash'></i> </button>  */}
                                </td>
                              </tr>
                              )) :
                              
                               <tr>
                                <td className='text-center' colSpan={7}>No record found</td>
                               </tr>
                              
                              }
                            </tbody>
                          </table>
                        </div>
                        { loading  ? 
                        <div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>          
                        : ''}
                        <div className="card-footer clearfix">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination justify-content-end">
                            {pagination ? pagination.map((pagi,index)=>(
                              <>
                                <li className={ pagi.url===null ? 'page-item disabled' :  pagi.active ?  'page-item active'  : 'page-item '}>
                                    { pagi.url===null ?
                                          <span class="page-link" onClick={() => {
        getPreviousRecords(pagi.label)
    }} >{pagi.label}</span> 
                                          : pagi.active===true ? 
                                              <span class="page-link active">
                                              {pagi.label} 
                                               <span class="sr-only">(current)</span>
                                              </span> : 
                                            <button class="page-link"  onClick={() => {
        getNextRecords(pagi.label)
    }}    >{pagi.label}</button> 
                                    }
                                </li>
                              </>
                            )) :'' }
                          </ul>
                        </nav>
                        { pagination ? 
                         <> <p className='float-right'>showing {page} of {pageTotal}</p></> 
                          :''
                         }                        
                        </div>   
                      </div>
                    </div>
                </div>
            </div>
        </section>                 
        </div>
        <Footer></Footer>
    </>
  )
}

export default Customers