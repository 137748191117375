import React,{useState} from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import axios from "axios";
import { useNavigate } from 'react-router-dom';



class CheckoutForm extends React.Component {
   
  
  handleSubmit = async event => {
    
    event.preventDefault();
      
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }
     /* Code for subscription Starts */
    const email =localStorage.getItem("email");
    const plan  =JSON.parse(localStorage.getItem("plan"));
    const planID = plan.plan_id;
    const planName = plan.plan_name;
    const planAmount = plan.amount;

    
    const card = elements.getElement(CardElement); 
    const {source, error} = await stripe.createSource(card, email);
    // create a payment method
    const paymentMethod = await stripe?.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        //name:"Customer",
        email:email,
      },
    });

      

     // call the backend to create subscription
     const response = await fetch("https://wizar-dev-backend.theintellify.net/api/v1/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        paymentMethod: paymentMethod?.paymentMethod?.id,
        name:"Customer",
        email:email,
        currency: "usd", 
        planID:planID,
        priceId:paymentMethod.id,
        source:source
      }),
    }).then((res) =>{ 
       if(res.status==200)
       {
        window.location.href="/otp";
       }
        console.log(res.json());
               //res.json()
    });

    // const confirmPayment = await stripe?.confirmCardPayment(
    //   response.clientSecret
    // );

    /* Code for subscription ends */


    
    //const card = elements.getElement(CardElement);
    
    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: elements.getElement(CardElement),
    // });

    // if (!error) {
    //   console.log("Stripe 23 | token generated!", paymentMethod);
    //   //send token to backend here
    // } 
    // else {
    //   console.log(error.message);
    // }

    
     //const result = await stripe.createToken(card);
     
    // if (result.error) {
    //   console.log(result.error.message);
    // } 
    // else {
    //   const amount =1;
    //   const data = {
    //     result   : result,
    //     amount:amount,
    //     email:'test@test.com'
    //   }
    //   console.log(result.token);
    //   axios.post('/create-payment-intent',data).then(res=>{
    //     if(res.data.status===200)
    //     {
    //       console.log(res.data);
    //         // localStorage.setItem("auth_token",res.data.token);
    //         // localStorage.setItem("auth_name",res.data.username);
    //         window.location.href ="/otp";
    //     }else if(res.data.status===401){
    //         //alert('Invalid Credentials');
    //     } else{
    //         //setLogin({...loginInput, error_list: res.data.validation_errors });
    //     }

    //    });
    // }
  };

  render() {
    return (
      <div>
        
        <form onSubmit={this.handleSubmit}>
          <CardSection />
          <button disabled={!this.props.stripe} className="btn-pay btn-sm btn-primary">
           Pay Now
          </button>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}