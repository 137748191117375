import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
const Login = () => {
    const navigate = useNavigate();
    const [loginInput,setLogin] = useState({
        email:'',
        error_list:[]
    });
    const handleInput =(e)=>{
        e.persist();
        setLogin({...loginInput,[e.target.name]:e.target.value});

    }

    const loginSubmit=(e)=>{
           e.preventDefault();
           const data = {
            email:loginInput.email

           }
           
           axios.post('/login',data).then(res=>{
            if(res.data.status===200)
            {
                localStorage.setItem("email",res.data.email);
                localStorage.setItem("auth_name",res.data.username);
                navigate('/otp');
            }else if(res.data.status===401){
                alert('Invalid Credentials');
            } else{
                setLogin({...loginInput, error_list: res.data.validation_errors });
            }

           });
    }


  return (
    <>  <body className="hold-transition login-page">
        <div className="login-box">
            <div className="login-logo">
                <a href="../../index2.html"><b>Wiz</b>AR</a>
            </div>
  
            <div className="card">
                <div className="card-body login-card-body">
                <p className="login-box-msg">Sign in to start your session</p>

                <form onSubmit={loginSubmit}>
                    <div className="input-group mb-3">
                    <input type="email" name="email" onChange={handleInput} value={loginInput.email} className="form-control" placeholder="Email"/>
                    <div className="input-group-append">
                        <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                        </div>
                    </div>
                    
                    </div>
                    <span>{loginInput.error_list.email}</span>
                    {/* <div className="input-group mb-3">
                    <input type="password" onChange={handleInput} value="" className="form-control" placeholder="Password"/>
                    <div className="input-group-append">
                        <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                        </div>
                    </div>
                    </div> */}
                    <div className="row">
                    <div className="col-4">
                        <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                    </div>
                    
                    </div>
                </form>
                <p className="mb-1">
                    <a href="forgot-password.html">I forgot my password</a>
                </p>
                {/* <p className="mb-0">
                    <a href="register.html" className="text-center">Register a new membership</a>
                </p> */}
                </div>
                
            </div>
        </div>
        </body>
    </>
  )
}

export default Login