import React from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar'; 
import {Link } from 'react-router-dom';
import Footer from './Footer';

const CustomerEdit = () => {
  return (
    <>
                <Navbar/>
        <Sidebar></Sidebar>
        <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Customer Edit</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                    <li className="breadcrumb-item active">Customer Edit</li>
                    </ol>
                </div>
                </div>
            </div>
        </div>        
        <section className="content">
            <div className="container-fluid">
            <div className="col-md-12">
            <div className="card card-dark">
                    <div className="card-header">
                    <h3 className="card-title">General</h3>

                    <div className="card-tools">
                        <Link to="/customers" className='btn btn-sm btn-primary'>Back </Link>
                        {/* <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i className="fas fa-minus"></i></button> */}
                    </div>
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label for="inputName">Customer Name</label>
                                    <input type="text" id="inputName" placeholder='Customer Name' className="form-control"/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label for="inputName">Customer Email</label>
                                    <input type="email" id="inputName" placeholder='Customer Email Address' className="form-control"/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label for="inputName">Contact Number</label>
                                    <input type="text" placeholder='Contact Number' id="inputName" className="form-control"/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label for="inputStatus">Status</label>
                                    <select className="form-control custom-select">
                                        <option selected disabled>Select </option>
                                        <option>Active</option>
                                        <option>Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label for="inputName">Created Date</label>
                                    <span className='badge badge-sm badge-default'>5/1/2023</span>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label for="inputName">Current  Plan</label>
                                    <span className='badge badge-sm badge-default'>5/1/2023</span>
                                </div>
                            </div>
                        </div>
                        
                    
                    </div>
                </div>
            </div>
         
            </div>
        </section>
        
        </div>
        <Footer></Footer>
    </>
  )
}

export default CustomerEdit