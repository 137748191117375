import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import axios from 'axios';

const Dashboard = () => {

    const [userType,setUserType]= useState('');
    const [userRole,setUserRole]= useState('');
    const [userDashboard,setUserDashboard]= useState('');
    
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    //console.log(JSON.stringify(auth_user));

    const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
    const config = {
      headers: {
        Authorization: bearer_token
      }
    };


    useEffect(()=>{
        getUserDashboard();
    })


    
    const getUserDashboard=()=>{
        const bodyParameters = {
          user: auth_user
        };

        axios.post('/getdashboard',bodyParameters,config).then(res=>{
            console.log("res"+JSON.stringify(res.data.data));
            if(res.status===200)
            {
                setUserRole(res.data.role);
                setUserDashboard(res.data.data);
            }else if(res.data.status===401){
                alert('Invalid Credentials');
            } else{
                
            }
        });
        
    }


    return (
        <>

            <Navbar />
            <Sidebar></Sidebar>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Dashboard</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Dashboard v1</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content" style={{"height":"570px"}}>
                    <div className="container-fluid">
                       { userRole=="Admin"  ? <>
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>150</h3>

                                        <p>Total Registered User</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <Link href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-6">
                                
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>53<sup className='fz-20'>%</sup></h3>

                                        <p>Total Models Generated</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars"></i>
                                    </div>
                                    <Link href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>44</h3>
                                        <p>Total Customers</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <Link href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>65</h3>
                                        <p>Unique Visitors</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-pie-graph"></i>
                                    </div>
                                    <Link href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        </>  : ''}
                        {/* user Dashboard */}
                        { userRole=="User" ? <> 
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{userDashboard.products_count}</h3>

                                        <p>Total Products</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-bag"></i>
                                    </div>
                                    {/* <Link href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link> */}
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-6">
                                
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{userDashboard.TotalPublised}<sup className='fz-20'>%</sup></h3>

                                        <p>Total Published</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars"></i>
                                    </div>
                                    {/* <Link href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link> */}
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{userDashboard.TotalRequested}</h3>
                                        <p>Total Requested</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    {/* <Link to="/requests" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link> */}
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                       {userDashboard.plans ? userDashboard.plans.map((plan,index)=>(

                                        <h3>{plan.plan_name}</h3>

                                       ))  :''}
                                        
                                        <p>Current Plan</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-pie-graph"></i>
                                    </div>
                                    {/* <Link href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link> */}
                                </div>
                            </div>
                            
                        </div>
                        </> : ''}
                    </div>
                </section>
            </div>
            <Footer></Footer>
            {/* <footer className="main-footer">
            <strong>Copyright &copy; 2014-2019 <a href="http://adminlte.io">AdminLTE.io</a>.</strong>
            All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 3.0.4
            </div>
        </footer> */}

        </>
    )
}

export default Dashboard