import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import Footer from './Footer';

const UserPayment = () => {
    const [userplans,setUserPlans] = useState([]);
    const fetchUserPlans=()=>{
        const auth_user = JSON.parse(localStorage.getItem("auth_user"));
        const url = '/userpayment?id='+auth_user.id;
        axios.get(url).then(res=>{

          if(res.status===200)
          {
            //console.log(res.data.data);
            setUserPlans(res.data.data);  
          } 
        });
    }
    useEffect(()=>{
        fetchUserPlans();
    },[])

  return (
    <>
        <Navbar/>
        <Sidebar></Sidebar>
        <div className="content-wrapper" >
          <div className="content-header">
              <div className="container-fluid">
                  <div className="row mb-2">
                  <div className="col-sm-6">
                      <h1 className="m-0 text-dark">Payments</h1>
                  </div>
                  <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item active">Payments</li>
                      </ol>
                  </div>
                  </div>
              </div>
          </div>        
          <section className="content">
              <div className="container-fluid">
                  <div className='row'>
                      <div className='col-12'>
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Payments Listing</h3>
                          </div>
                  
                          <div className="card-body">
                            <table id="example1" className="table table-bordered table-striped">
                              <thead>
                              <tr>
                                <th>Id</th>
                                <th>Plan</th>
                                <th>Amount</th>
                                <th>Customer ID</th>
                                <th>Subscription ID</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                  userplans.map((userplan, index) =>  (
                                  <tr key={userplan.id}>
                                  <td>{index+1}</td>
                                  <td>{userplan.plan_name}</td>
                                  <td>{userplan.amount}</td>
                                  <td>{userplan.customer_id}</td>
                                  <td>{userplan.subscription_id}</td>
                                  <td></td>
                                  <td> 
                                      
                                      <Link className='btn btn-sm btn-info ml-1' to={{ pathname:'/userpayments/'+userplan.id}}><i className='fas fa-eye' ></i></Link> 
                                      
                                  </td>
                                  </tr>
                                  ))   
                              }
                              
                                  
                                
                              </tbody>
                            </table>
                          </div>
                          <div className="card-footer clearfix">
                            <ul className="pagination pagination-sm m-0 float-right">
                              <li className="page-item"><Link className="page-link" to="#">«</Link></li>
                              <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                              <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                              <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                              <li className="page-item"><Link className="page-link" to="#">»</Link></li>
                            </ul>
                          </div>   
                        </div>
                      </div>
                  </div>
              </div>
          </section>
        </div>
        <Footer></Footer>
    </>
  )
}

export default UserPayment