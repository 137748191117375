import React from 'react'
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({children}) => {
    
        const email = localStorage.getItem("email");
         
        return  email!==null ? children : <Navigate to="/login" />;
    
}

export default PrivateRoute