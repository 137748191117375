import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Settings = () => {
    
    


    const [apikey,setKey] = useState(''); //use to store api key
    const [apisecret,setSecret] = useState(''); //use to store api secret
    const [isVisible, setIsVisible] = useState(false); //use to check state is_visible 1 or 2
    const [isRegenerate, setIsRegenerate] = useState(false); //use to check state is regenerated
    const [loading,setLoading]=useState(false);


    useEffect(()=>{
        setLoading(true);
        const checkApiIsVisible=async()=>{
            const user = JSON.parse(localStorage.getItem("auth_user"));
            console.log('test'+user.id);
            const data = {
                userid:user.id
            }
            axios.post('/checkApiKeyVisible',data).then(res=>{
                console.log("RES DATA"+JSON.stringify(res.data));
                if(res.data.status===200)
                {
                    
                    if(res.data.data.is_visible===2){
                        setKey(res.data.data.api_key);
                        setIsRegenerate(true);
                        //setIsVisible(false);

                    }
                    setLoading(false);
                } setLoading(false);
            });  
        }

        checkApiIsVisible();
        

    },[isVisible]);
    console.log("response"+isVisible);

    const generateKeys=(e)=>{
        e.preventDefault();
        
        const user = JSON.parse(localStorage.getItem("auth_user"));
        const data = {
            userid:user.id
        }
        axios.post('/generatekeys',data).then(res=>{
            if(res.status===200)
            {   console.log(res.data.secret);
                setKey(res.data.data.api_key);
                setSecret(res.data.secret);
                setIsVisible(true);
            } 
        });    
    }
    const regenerateKeys = (e)=>{
        e.preventDefault();
        const user = JSON.parse(localStorage.getItem("auth_user"));
        const data = {
            userid:user.id
        }
        axios.post('/regenerateKeys',data).then(res=>{
            if(res.data.status===200)
            {
                setKey(res.data.data.api_key);
                setSecret(res.data.secret);
                setIsVisible(true);
                setIsRegenerate(true);              
                
            } else{
                // setIsVisible(true);
                //console.log("isvisible"+isVisible);
            }
        }); 
        
    }

  

  return (
    <>
            <Navbar />
            <Sidebar></Sidebar>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark"> Settings </h1>
                                
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link href="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Settings</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content" style={{"height":"570px"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-md-12'>
                            <div className="card card-info">
                                <div className="card-header">
                                    <h3 className="card-title">Your Access Details</h3>
                                </div>
                                <form className="form-horizontal">
                                    <div className="card-body">
                                        
                                        <div className="form-group row">
                                            <label  className="col-sm-2 col-form-label">API KEY</label>
                                            <div className="col-sm-8">
                                                <div className="input-group input-group-sm">
                                                    <input type="text" className="form-control"  value={apikey} placeholder='API KEY' readOnly/>
                                                    <span className="input-group-append">
                                                        <button type="button" className="btn btn-info btn-flat" >Copy</button>
                                                    </span>
                                                </div>
                                            </div>    
                                        </div>
                                        <div className="form-group row">
                                            <label  className="col-sm-2 col-form-label">Secret  </label>
                                            <div className="col-sm-8">
                                                <div className="input-group input-group-sm">
                                                   
                                                { isVisible && isRegenerate ? 
                                                    <>
                                                    <input type="text" className="form-control"   value={apisecret} readOnly/> 
                                                    <span className="input-group-append">
                                                        <button type="button" className="btn btn-info btn-flat"  >Copy!</button>
                                                    </span>
                                                    </>
                                                : <><input type="text" className="form-control" value={'****'} readOnly/>
                                                    <span className="input-group-append">
                                                        <button type="button" className="btn btn-info btn-flat" >Copy!</button>
                                                    </span> 
                                                  </>}    
                                                </div>
                                            </div>    
                                        </div>
                                        <div className="form-group row">
                                            <p className="col-sm-12 "><b>Note :-</b> Save your API KEY and SECRET for the further use,as it is only generated once.</p>
                                        </div>
                                    </div>
                                    {loading ? 'Loading...' :
                                    (<div className="card-footer">
                                     
                                        { (isVisible ===true && isRegenerate===true) || (isVisible ===false && isRegenerate===true) 
                                            ? <button type="button" className="btn ml-5 btn-info" onClick={regenerateKeys}>ReGenerate</button> : 
                                            <button type="button" className="btn ml-5 btn-info" onClick={generateKeys}>Generate</button>
                                        }
                                    </div>)
                                    }

                                    
                                </form>
                            </div>
                            </div>  
                        </div>
                    </div>

                </section>
            </div>
            <footer className="main-footer">
                <strong>Copyright &copy; 2014-2019 <a href="http://adminlte.io">AdminLTE.io</a>.</strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 3.0.4
                </div>
            </footer>
    </>
  )
}

export default Settings