import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Link,useParams } from 'react-router-dom';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserpaymentView = () => {
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [userplans,setUserPlans] = useState([]);
    const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
    const config = {
      headers: {
        Authorization: bearer_token
      }
    };
    useEffect(()=>{
        getPaymentDetail();
    },[]);



    const getPaymentDetail = ()=>{
        setLoading(true);
        axios.get('/userpayment/'+id,config).then((res)=>{
            setLoading(false);
            setUserPlans(res.data.data);
            //console.log(res.data.data[0]);
            //console.log(res.data.data[0]);
            console.log("Plans"+res.data.data);
        }).catch((err)=>{
            console.log("ERR"+err);
            setLoading(false);
            toast.error("No Record Found");
        });

    }

    const unsubscribe=(id)=>{
        const bodyParameters = {
            subid: id
          };
        axios.post('/cancelsubscription',bodyParameters).then((res)=>{
            setLoading(false);
            //console.log(res.data.data[0]);
            //console.log(res.data.data[0]);
            toast.success("Subscription Successfully Cancelled");
            console.log("Subscription Response"+res.data.data);
        }).catch((err)=>{
            console.log("ERR"+err);
            setLoading(false);
            toast.error("No Record Found");
        });
        console.log("unsubscribe the plan"+id);
    }
   
    const updateSubscription=(id)=>{
        const bodyParameters = {
            subid: id
          };
          setLoading(true);
        axios.post('/updatesubscription',bodyParameters).then((res)=>{
            setLoading(false);
            //console.log(res.data.data[0]);
            //console.log(res.data.data[0]);
            toast.success("Subscription Successfully Updated");
            console.log("Subscription Response"+res.data.data);
        }).catch((err)=>{
            console.log("ERR"+err);
            setLoading(false);
            toast.error("No Record Found");
        });


    }













    return (
        <>
            <Navbar />
            <Sidebar></Sidebar>
            <div className="content-wrapper" >
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Payment View</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Payment View</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                <ToastContainer />
                    <div className="container-fluid">
                        
                        <div className='card card-primary card-outline'>
                            <div className='card-body box-profile'>
                               <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <Link to="/userpayments" className='btn btn-sm btn-primary float-right'>Back</Link>
                                    </div>
                               </div>
                                { userplans.map((plan,index)=>(
                                <> 
                                <div className='row' key={1}>
                                    
                                    <div className='col-md-8' style={{"margin-top":"-35px"}}>
                                        <h3 className='profile-username text-center'>Payment Details Of   {plan.name}    </h3>
                                        <ul className="list-group list-group-unbordered mb-3">
                                            <li className="list-group-item">
                                                <b>Customer ID</b> <span className="float-right">{plan.plans[0].customer_id} </span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Subscription ID</b> <span className="float-right">{plan.plans[0].subscription_id} </span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Status</b> <span className="float-right">{plan.plans[0].subscription_status} </span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Started Date</b> <span className="float-right">{plan.plans[0].start_date} </span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Ending Date</b> <span className="float-right">{plan.plans[0].end_date} </span>
                                            </li>
                                            
                                            {/* <li className="list-group-item">
                                                <b>Card Number</b> <span className="float-right">4242 42424 4242 4242</span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Card Type</b> <span className="float-right">Visa</span>
                                            </li> */}
                                            {/* <li className="list-group-item">
                                                <b>Payment Status</b> <span className="badge badge-success float-right">Active</span>
                                            </li> */}
                                            {plan.plans[0].subscription_status !=="trialing" ? 
                                            <>
                                            <li className="list-group-item">
                                                <b>Payment Date</b> <span className="badge badge-primary float-right">{plan.plans[0].plan_name}</span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Next Billing Date</b> <span className="badge badge-info float-right">4/12/2023</span>
                                            </li>
                                            </>
                                            : ''}
                                        </ul>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="col-12 col-md-12">
                                            <div className="info-box bg-light shadow-lg">
                                                <div className="info-box-content">
                                                    <span className="info-box-text text-center text-muted">Active Plan</span>
                                                    <span className="info-box-number text-center text-muted mb-0">{plan.plans[0].plan_name}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <div className="info-box bg-danger shadow-lg">
                                                <div className="info-box-content">
                                                    <span className="info-box-text text-center text-white">Expires On</span>
                                                    <span className="info-box-number text-center text-white mb-0">20/12/2023</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <div className="info-box bg-success shadow-lg">
                                                <div className="info-box-content">
                                                    <span className="info-box-text text-center text-white">Amount Paid</span>
                                                    <span className="info-box-number text-center text-white mb-0">$ {plan.plans[0].amount} <span>
                                                    </span></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <div className="card shadow p-3 mb-3 bg-gradient-dark rounded">
                                        <div className="card-body">
                                        <h5 className="text-center">Free Plan</h5>
                                            <div class="ribbon-wrapper ribbon-xl">
                                                <div class="ribbon bg-danger text-xl">
                                                Free Plan
                                                </div>
                                            </div>
                                            {/* <h5 className="text-center">Free Plan</h5> */}
                                            <center>
                                            <p className="card-text">Free Plan for 30 days </p>  
                                            { plan.plans[0].subscription_status==="trialing" ? 
                                                <button type='button' className='btn btn-sm btn-danger'
                                                    onClick={() => { if (window.confirm('Are you sure you want to Cancel Free ?')) { unsubscribe(plan.plans[0].subscription_id) }; }}
                                                >Cancel</button> 
                                                : <button type='button' className='btn btn-sm btn-success'>Activate</button> }
                                                                            
                                            </center>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card shadow p-3 mb-3 bg-gradient-dark  rounded">
                                        <div className="card-body">
                                            <h5 className="text-center">Advanced Plan</h5>
                                            <div class="ribbon-wrapper ribbon-xl">
                                                <div class="ribbon bg-info text-xl">
                                                Advanced
                                                </div>
                                            </div>
                                            <center>
                                            <p className="card-text">Basic Plan for 3 months </p> 
                                            { plan.plans[0].plan_id==2 && plan.plans[0].subscription_status!=="trialing" ? <button type='button' className='btn btn-sm btn-danger'>Cancel</button> 
                                            : <button type='button' className='btn btn-sm btn-success collapsed' data-toggle="collapse" data-parent="#accordion" href="#collapseOne"  aria-expanded="false">Activate</button> }
                                              
                                            <div id="collapseOne" class="panel-collapse in collapse" >
                                                <div class="card-body">
                                                    <>
                                                    <div className='row'>
                                                        <div className='col-12 mb-1'>
                                                        <input type='text' name="source_id" value={plan.plans[0].source_id} />
                                                        <input type='text' name="customer_id" value={plan.plans[0].customer_id} />
                                                        <input type='text' name="subscription_id" value={plan.plans[0].subscription_id} />
                                                        <input placeholder='Card Number' className='form-control' value={'**** **** **** ****'+plan.plans[0].last_four_digit}/>
                                                        </div>
                                                        <div className='col-3'>
                                                        <input placeholder='Month' className='form-control' value={ plan.plans[0].exp_month} />
                                                        </div>
                                                        <div className='col-3'>
                                                        <input placeholder='Year' className='form-control' value={ plan.plans[0].exp_year}/>
                                                        </div>
                                                        <div className='col-6'>
                                                        <input placeholder='Card Number' className='form-control' value={ plan.plans[0].exp_month} />
                                                        </div>
                                                        <div className='col-12 mt-1'>
                                                         <button type='button' className='btn btn-sm btn-primary' onClick={() => { if (window.confirm('Are you sure you want to Update subscription ?')) { updateSubscription(plan.plans[0].subscription_id) }; }} >Pay Now</button>
                                                        </div>
                                                    </div> 
                                                        
                                                        
                                                       </>
                                                </div>
                                            </div>                   
                                            </center>
                                         </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card shadow p-3 mb-3 bg-gradient-dark  rounded">
                                        <div className="card-body">
                                            <h5 className="text-center">Pro Plan</h5>
                                            <div class="ribbon-wrapper ribbon-xl">
                                                <div class="ribbon bg-success text-xl">
                                                PRO
                                                </div>
                                            </div>
                                            <center>
                                            <p className="card-text">Pro Plan 1 Year Access</p>
                                            { plan.plans[0].plan_id==3 && plan.plans[0].subscription_status!=="trialing" ? <button type='button' className='btn btn-sm btn-danger'>Cancel</button> : <button type='button' className='btn btn-sm btn-success'>Activate</button> }
                                              
                                              
                                            </center>
                                            
                                        </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className='row'>
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">Payment History</h3>
                                            </div>
                                            <div className="card-body">
                                                <div id="accordion">
                                                         
                                                { plan.plans.map((plans,index)=>(   
                                                    <>
                                                    <div className="card card-dark">
                                                        <div className="card-header">
                                                            <h4 className="card-title">
                                                                <a data-toggle="collapse" data-parent="#accordion" href={'#collapse'+plans.id} >
                                                                    {plans.plan_name}  {new Date(plans.created_at).toLocaleDateString()}  (Click to view)
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id={'collapse'+plans.id} className="panel-collapse collapse in">
                                                            <div className="card-body">
                                                                
                                                                <ul className="list-group list-group-unbordered mb-3">
                                                                    <li className="list-group-item" style={{"border-top":"none"}}>
                                                                        <b>Customer ID</b> <span className="float-right">{plans.customer_id}</span>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <b>Subscription ID</b> <span className="float-right">{plans.subscription_id}</span>
                                                                    </li>
                                                                    {/* <li className="list-group-item">
                                                                        <b>Card Number</b> <span className="float-right">4242 42424 4242 4242</span>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <b>Card Type</b> <span className="float-right">Visa</span>
                                                                    </li> */}
                                                                    <li className="list-group-item">
                                                                        <b>Payment Status</b> <span className="badge badge-success float-right">Active</span>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <b>Payment Date</b> <span className="badge badge-primary float-right">{new Date(plans.created_at).toLocaleDateString()}</span>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <b>Next Billing Date</b> <span className="badge badge-info float-right">4/12/2023</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                ))}  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                                ))}  
                                <div className='row'>
                                    <p className='text-center text-large mx-auto'>No Record Found</p>
                                </div>
                                 
                            </div>
                        </div>
                        

                    </div>
                </section>
            </div>
            <Footer></Footer>
        </>
    )
}

export default UserpaymentView