import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';


const RequestsView = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({});

    const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
    const config = {
        headers: {
            Authorization: bearer_token
        }
    };
    useEffect(() => {
        requestView();
    }, []);

    const bodyParameters = {
      id: id
    };

    const requestView = () => {
        setLoading(true);
        axios.get('/requests/' + id + '/requestview', config).then((res) => {
             
            setInputs({
                customer_name: res.data.data.users.name,
                feedback:null,
                product_name: res.data.data.product_name,
                frontend_url: res.data.data.frontend_url,
                status: res.data.data.status,
                product_images: res.data.data.product_images
            });
            setLoading(false);
        });
    }


 

  return (
    <>
    <Navbar/>
    <Sidebar></Sidebar>
    <div className="content-wrapper">
    <div className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
                <h1 className="m-0 text-dark">Request View</h1>
            </div>
            <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                <li className="breadcrumb-item active">Request View</li>
                </ol>
            </div>
            </div>
        </div>
    </div>        
    <section className="content" style={{"height":"570px"}}>
        <div className="container-fluid">
            <div className='row'>
            <div className='col-12'>
                      <div className="card card-dark">
                        <div className="card-header">
                          <h3 className="card-title">Request View</h3>
                          <Link to="/requests" className='btn btn-sm btn-primary float-right'>Back</Link>
                          
                        </div>
                        <div className="card-body">
                            
                            <div className='row'>
                            <h5>Basic Information</h5>
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Value</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>Customer Name</td>
                                        <td>{inputs.customer_name}</td>
                                        <td className="text-right py-0 align-middle">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Product Name</td>
                                        <td>{inputs.product_name}</td>
                                        <td className="text-right py-0 align-middle">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Frontend URL</td>
                                        <td>{inputs.frontend_url}</td>
                                        <td className="text-right py-0 align-middle">
                                        
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Current Status</td>
                                        <td>  { inputs.status==="5" ? <span className='badge bg-success'>Is Published</span> :''} 
                                              { inputs.status==="4" ? <span className='badge bg-default'>Is Review</span> :''}
                                              { inputs.status==="3" ? <span className='badge bg-primary'>In Progess</span> :''}
                                              { inputs.status==="2" ? <span className='badge bg-danger'>Is Rejected</span> :''}
                                              { inputs.status==="1" ? <span className='badge bg-warning'>Is Requested</span>:''}
                                        </td>
                                        <td className="text-right py-0 align-middle">
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                                  
                            </div>
                        </div>
                        <hr/>
                        <h4 className='ml-3'>Product Images</h4>
                        <div className='row'>
                            { (inputs.product_images && inputs.product_images !=="") ? 
                                  inputs.product_images.map((images,index)=>{
                                    return(<div className="col-md-3 col-sm-6 col-12 ml-1">
                                    <div class="info-box ">
                                        <div class="info-box-content">
                                        <img className="img img-responsive border-2"  src={images.url} alt=""/>
                                        <div>{images.file_name}</div>
                                        </div>
                                    </div>

                                    {/* <img className="img img-responsive border-2" style={{"border":" 2px solid #705f39",
    "border-radius":"15px","padding": "5px"}} src={images.url} alt=""/> */}
                                    </div>)}) : ''
                            }
                        </div>
                        <hr/>
                         

                        { loading ? <div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>:''}          
                        <div className="card-footer clearfix">
                          <Link to="/requests" className='btn btn-sm btn-success'>Back to Requests</Link>
                        </div>   
                      </div>
                    </div>
            </div>
        </div>
    </section>
    
    </div>
 </>
  )
}

export default RequestsView