import React from 'react'
import axios from 'axios';
const udpateEmailVerifcation=(email)=>{

    console.log("update and verify"+email);
    const payload={
        'email': email        
    }
    
     console.log("selected payload"+JSON.stringify(payload));
     const headers = {'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'Access-Control-Allow-Methods':'GET,POST,PATCH,OPTIONS'}
    axios({
        method: 'GET',
        url: 'verifyemail',
        params: {
          email: email
        }, // you are sending body instead
        headers: headers, 
      }).then(response => {
        console.log("respose is"+JSON.stringify(response));
        
        
        if (response.data.success===true) {
          localStorage.setItem("email",email);        
          localStorage.setItem("plan",JSON.stringify(response.data.plan));        
          
            window.location = "/payment"; 
             console.log("successfully send Redirect to Payment");
        }
    
        
    }).catch((error) => {
        // catch any unexpected errors
        console.log(error);
    })

}

const VerifyEmail = () => {

    let search = window.location.search;
    const searchParam = new URLSearchParams(search);
    const email = searchParam.get('email');
   
     udpateEmailVerifcation(email);     





  return (
    <div>Hello VerifyEmail</div>
  )
}

export default VerifyEmail