import React from 'react'
import {loadStripe} from '@stripe/stripe-js';
import {
  
  Elements
  
} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';


const Payment = () => {
 
  const stripePromise = loadStripe('pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3');
  //const clientSecret = "sk_test_51LIneAJhPDAF2mzKkdV1NgGdQC5uCYnNrwAkWeHDrB10GqMpeQmHA9SMJl7oe8rB1nU5RQFEPjlACle9dka1sVzu00jMsPYybb";

  const email =localStorage.getItem("email");
  const plan  = JSON.parse(localStorage.getItem("plan"));
  const planName = plan.plan_name;
  const planAmount = plan.amount;
  console.log("email"+email); 
  console.log("plan"+plan.plan_name); 

  
  return (
    <>
    <center>
      
      <div className="card card-dark" style={{width:"600px",marginTop:"100px"}} >
        <div className='card-header'>Complete Your Payment</div>
        <h5>Your Email {email} </h5>
        <div className="card-body">
        <div className="product-info">
          <h3 className="product-title">{planName}</h3>
          <h4 className="product-price">${planAmount}</h4>
        </div> 
        <Elements stripe={stripePromise} >
            <CheckoutForm />
          </Elements>
        </div>
      </div>
    </center>
    </>
    
  )
}

export default Payment