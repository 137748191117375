import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const CustomerView = () => {
    const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
    const config = {
        headers: {
            Authorization: bearer_token
        }
    };
    const { id } = useParams();
    //Get list of customer 
    const [customers, setCustomers] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [loading, setLoading] = useState(false);

    const getCustomer = () => {


        const auth_user = JSON.parse(localStorage.getItem("auth_user"));
        const bodyParameters = {
            user: auth_user
        };
        const customerURL = '/customers/' + id;
        setLoading(true);
        axios.get(customerURL, config).then(res => {
            if (res.status === 200) {
                setCustomers(res.data.data);
                setLoading(false);
            }
        }).catch((err) => {
            //alert("Unauthorised Access");
        });
    }

    useEffect(() => {
        getCustomer();
    }, []);




    return (
        <>
            <Navbar />
            <Sidebar></Sidebar>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Customer View</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Customer</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content" style={{"height":"570px"}}>
                    <div className="container-fluid">

                        <div className='card card-primary card-outline'>
                            <div className='col-md-12'><Link className='btn btn-sm btn-primary float-right mt-1' to="/customers">Back</Link></div>

                            <div className='card-body box-profile'>
                            { loading  ? 
                        <div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>          
                        :      <>
                                <div className='row'>

                                    <div className='col-md-8' style={{ "margin-top": "-37px" }}>
                                        <h3 className='profile-username text-success text-bold text-capitalize text-center'>{customers.name} </h3>
                                        <ul className="list-group list-group-unbordered mb-3">
                                            <li className="list-group-item">
                                                <b>Email Address</b> <span className="float-right">{customers.email}</span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Current Status</b>
                                                {customers.user_status === 1 ?
                                                    <span className="badge badge-success float-right">Active</span>
                                                    : <span className="badge badge-danger float-right">Inactive</span>
                                                }
                                            </li>
                                            {customers.plans ? customers.plans.map((data, index) => (
                                                <>
                                                    <li className="list-group-item">
                                                        <b>Active Plan</b> <span className="badge badge-primary float-right">{data.plan_name}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>Amount</b> <span className="badge badge-primary float-right">{data.amount}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>Plan Start Date</b> <span className="badge badge-info float-right">{new Date(data.created_at).toLocaleDateString()}</span>
                                                    </li>
                                                    <li className="list-group-item" style={{"border":"none"}}>
                                                        <b>Plan Expires On</b> <span className="badge badge-info float-right">4/12/2023</span>
                                                    </li>
                                                </>
                                            )) : ''}
                                        </ul>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="col-12 col-md-12">



                                        </div>
                                        <div className="col-12 col-md-12">
                                            <div className="info-box bg-info">
                                                <div className="info-box-content">
                                                    <span className="info-box-text text-center text-white">Total Products</span>
                                                    <span className="info-box-number text-center text-white mb-0">{customers.products_count}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <div className="info-box bg-warning">
                                                <div className="info-box-content">
                                                    <span className="info-box-text text-center text-white">Total Requested</span>
                                                    <span className="info-box-number text-center text-white mb-0">{customers.TotalRequested}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <div className="info-box bg-success">
                                                <div className="info-box-content">
                                                    <span className="info-box-text text-center text-white">Total Published</span>
                                                    <span className="info-box-number text-center text-white mb-0">{customers.TotalPublised} <span>
                                                    </span></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                </div>
                                
                                <div id="accordion">
                                {customers.products ? customers.products.map((product, index) => (             
                                        <div className="card card-dark">
                                            <div className="card-header">
                                            <h4 className="card-title">
                                                <a data-toggle="collapse" data-parent="#accordion"  href={"#collapse"+index}>
                                                 {product.product_name} 
                                                </a>
                                                
                                            </h4>
                                            <span className='float-right text-white'>Click on product name to view</span>
                                            </div>
                                            <div id={"collapse"+index} className="panel-collapse collapse in">
                                            <div className="card-body">
                                            <table class="table table-sm">
                                                <thead>
                                                    <tr>
                                                    
                                                    <th>Title</th>
                                                    <th>Values</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Created Date</td>
                                                        <td>
                                                        { new Date(product.created_at).toLocaleDateString()}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status</td>
                                                        <td>
                                                        { product.status==="5" ? <span className='badge bg-success'>Is Published</span> :''} 
                                                        { product.status==="4" ? <span className='badge bg-default'>Is Review</span> :''}
                                                        { product.status==="3" ? <span className='badge bg-primary'>In Progess</span> :''}
                                                        { product.status==="2" ? <span className='badge bg-danger'>Is Rejected</span> :''}
                                                        { product.status==="1" ? <span className='badge bg-warning'>Is Requested</span>:''}
                                                        
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Frontend URL</td>
                                                        <td>
                                                            {product.frontend_url}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Images</td>
                                                        <td>
                                                            <div className='row mx-auto'>
                                                            { product.product_images ? product.product_images.map((productImg, index) => (             
                                                            <>
                                                                <div className='col-md-4 m-2 shadow rounded'>
                                                                    <label>{productImg.file_name}</label>
                                                                    <img className='img-fluid ' src={productImg.url} alt='' />
                                                                </div>
                                                                </>
                                                        )) :''
                                                        }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    
                                                        
                                                        { product.product_models ? product.product_models.map((productImg, index) => (             
                                                            <tr>
                                                                <td>Models</td>
                                                                <td>
                                                                    <>
                                                                    <div className='row mx-auto'>
                                                                        <div className='col-md-4 m-2 shadow rounded'>
                                                                            <label>Name : <span>{productImg.model_name}</span></label>
                                                                            <label>Type : <span>{productImg.model_type}</span></label>
                                                                            <center><Link className='btn btn-sm btn-warning mb-3' to="/">Download <i className='fas fa-download'></i> </Link></center>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    </>
                                                                </td>
                                                            </tr>
                                                        )) :''
                                                        }
                                                 

                                                    


                                                </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        </div>
                                )) : '' }        
                  
                </div>                    







                               </>
                            } 
                            </div>
                        </div>



                    </div>

                </section>

            </div>
            <Footer></Footer>
        </>
    )
}

export default CustomerView