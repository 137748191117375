import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const RequestsEdit = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [inputs, setInputs] = useState({});
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
    const config = {
        headers: {
            Authorization: bearer_token
        }
    };
    useEffect(() => {
        fetchProduct();
    }, []);


    const fetchProduct = () => {
        setLoading(true);
        axios.get('/requests/' + id + '/edit', config).then((res) => {
            console.log(res.data.data);
            setInputs({
                customer_name: res.data.data.users.name,
                feedback:null,
                product_name: res.data.data.product_name,
                frontend_url: res.data.data.frontend_url,
                status: res.data.data.status,
                product_images: res.data.data.product_images

            });
            setLoading(false);
        });
    }

    const [feedback, setFeedback] = useState('');
    const handleFeedbackChange =(event)=>{
        setFeedback(event.target.value);
    } 
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (event.target.value === "2") {
            document.getElementById('feedback').style.display = "block";
        } else {
            document.getElementById('feedback').style.display = "none";
        }

        setInputs(values => ({ ...values, [name]: value }))
    }

    const submitForm = (e) => {

        e.preventDefault();
        const bodyParameters = {
            inputs: inputs,
            id: id,
            feedback:feedback
        };


        axios.post('/requestUpdate', bodyParameters, config).then((res) => {
            console.log(res.status);
            if(res.status===200)
            {
                alert(res.data.message);
                navigate('/requests');
            } else {
                alert(res.data.message);
            }
            //window.location.reload();
            //navigate('/');
        })

    }

    return (
        <>
            <Navbar />
            <Sidebar></Sidebar>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Request Edit</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Request Edit</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="col-md-12">
                            <div className="card card-dark">
                                <div className="card-header">
                                    <h3 className="card-title">Request Edit</h3>

                                    <div className="card-tools">
                                        <Link to="/requests" className='btn btn-sm btn-primary'>Back </Link>
                                        {/* <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i className="fas fa-minus"></i></button> */}
                                    </div>
                                </div>
                                <div className="card-body">
                                { loading ? 
                                <div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div> :
                                <form onSubmit={submitForm} >   
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label for="inputName">Product Name</label>
                                                <input type="text" name='product_name' id="inputName" readOnly placeholder='Product Name' className="form-control" value={inputs.product_name || ''} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label for="inputName">Customer Name</label>
                                                <input type="text" name='customer_name' id="inputName" readOnly placeholder='Product Name' className="form-control" value={inputs.customer_name || ''} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="inputSpentBudget">Frontend URL</label>
                                                <input type="text" readOnly name='frontend_url' id="inputSpentBudget" className="form-control" value={inputs.frontend_url || ''}
                                                    onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label for="inputStatus">Status</label>
                                                <select id='current-status' value={inputs.status} name='status' onChange={handleChange} className='form-control'>
                                                    <option value='select'>Select</option>
                                                    <option value="1" selected >Is Requested</option>
                                                    <option value="2" selected>Is Rejected</option>
                                                    <option value="3" selected>In Progress</option>
                                                    <option value="4" selected>Is Reviewed</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="form-group">
                                                <label for="inputName">Image</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {(inputs.product_images && inputs.product_images !== "") ?
                                            inputs.product_images.map((images, index) => {
                                                return (
                                                    <div className="col-md-5 card ml-2" key={index}>
                                                        <img className="card-img-top  " src={images.url} alt="" />
                                                        <div className="card-body text-center p-2">
                                                            <h5 className="card-title float-none">{images.file_name}</h5>
                                                            {/* <p className="card-text "><button className='btn btn-xs btn-danger'>Remove</button></p> */}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : ''}
                                    </div> 
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div id="feedback">
                                                {inputs.status === "2" ?
                                                    <div className='form-group'>
                                                        <label>Feedback</label>
                                                        <textarea  name='feedback' className='form-control' style={{"border":"1px solid #000"}}  value={feedback} onChange={handleFeedbackChange}></textarea>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                                              
                                    <div className="card-footer clearfix">
                                        <button type="submit" className='btn btn-sm btn-success'>Save </button>
                                        <button className='btn btn-sm btn-default ml-2'>Cancel</button>
                                    </div>
                                </form>
                                 }
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
            <Footer></Footer>
        </>
    )
}

export default RequestsEdit