import React from 'react';
import '../admin/plans.css';
//import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios'; 
//const stripePromise = loadStripe('pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3');

const Plans = () => {
  
    const proceedToPay=()=>{
        let selectedPlan  = "";
        var ele = document.getElementsByName('plan');
              
            for(var i = 0; i < ele.length; i++) {
                if(ele[i].checked)
                  selectedPlan  = ele[i].value;
             
            }
            console.log(selectedPlan);
           //document.getElementById("selected_plan_amount").value=selectedPlan;
           let selectedPlanName='';
           
          if(selectedPlan==="1"){
            selectedPlanName='BASIC PLAN';
            
            document.getElementById("selected_plan_amount").value=1;
            document.getElementById("plan_id").value=selectedPlan;
            
          }
          if(selectedPlan==="2"){
              selectedPlanName='PREMIUM PLAN';
              document.getElementById("selected_plan_amount").value=10;
              document.getElementById("plan_id").value=selectedPlan;
          }
          if(selectedPlan==="3"){
              selectedPlanName='Plan C';
              document.getElementById("selected_plan_amount").value=0;
              document.getElementById("plan_id").value=selectedPlan;
          }
          document.getElementById("selected_plan_name").innerHTML=selectedPlanName;
            
    }

    async function handleCheckout() {

        var name = document.getElementById("customer_name").value;
        var email = document.getElementById("customer_email").value;
        var contact_number = document.getElementById("contact_number").value;
        var amount =  document.getElementById("selected_plan_amount").value;
        var plan_id = document.getElementById('plan_id').value;
        let selectPlan='';
        if(plan_id==="1"){
            selectPlan='BASIC PLAN';
        }
        if(plan_id==="2"){
            selectPlan='PREMIUM PLAN';
        }
        if(plan_id==="3"){
            selectPlan='Plan C';
        }
        console.log("selected plan"+selectPlan);
        if(name==="" || email==="")
        {
            alert("name and email are required fields");
        }

        console.log("HERE AJAX SELECTED PLAN"+selectPlan);

        const payload={
            'customer_name': name,
            'email': email,
            'contact_number':contact_number,
            'selectedPlan':selectPlan,
            'plan_id':plan_id,
            'amount':amount
        }
         console.log("selected payload"+JSON.stringify(payload));
        axios({
            method: 'post',
            url: '/send-mail',
            data: payload, // you are sending body instead
            headers: {
             // 'Authorization': `bearer ${token}`,
            'Content-Type': 'application/json'
            }, 
          }).then(response => {
            console.log("respose is"+JSON.stringify(response));
            
            
            if (response.data.success===true) {
                window.location = "/success"; 
                 console.log("successfully send Redirect to OTP PAGE");
            }
        
            
        }).catch((error) => {
            console.log(error);
        })

   
    }

   const selectPlan=(event)=>{
          console.log(event.target.value);



    }

  return (
    <>
          <div className="container mt-5">
            <div className="row">
                                
                    <div className="col-md-4 col-lg-4 col-sm-4">
                        <label>
                          <input  onChange={selectPlan.bind(this)} type="radio" name="plan" className="card-input-element" id="selected-plan" value="1" />
                            <div className="card card-default card-input">
                              <div className="card-header">BASIC PLAN</div>
                              <div className="card-body">
                              <h4>FREE </h4>
                               <span>Click to select plan</span>
                               <p>After Free plan $1 applicable</p>
                              </div>
                            </div>

                        </label>
                        
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4">
                        <label>
                          <input onChange={selectPlan.bind(this)} type="radio" name="plan"  className="card-input-element" value="2" />
                            <div className="card card-default card-input">
                              <div className="card-header">PREMIUM PLAN</div>
                              <div className="card-body">
                                <h4>Cost $10</h4>
                                
                                 <span>Click to select plan</span>
                               <p>Price applicable as displayed</p>
                              </div>
                            </div>
                        </label>                    
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4">
                        <label>
                          <input onChange={selectPlan.bind(this)} type="radio" name="plan" className="card-input-element" value="3"/>
                            <div className="card card-default card-input">
                              <div className="card-header">Plan C Cost $3</div>
                              <div className="card-body">
                                 Click to select plan
                              </div>
                            </div>
                        </label>                    
                    </div>
                
            </div>
            <div className="row mt-5">
                <div className="col-md-4"></div>
                <div className="col-md-4 ">
                    <center>
                    <button type="button"  className="btn btn-sm btn-primary" data-toggle="modal" data-target="#exampleModalCenter" onClick={proceedToPay}>Pay Now</button>

                </center>
                </div>
                <div className="col-md-4"></div>
            </div>
         
            <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        Hey you have selected <span id="selected_plan_name"></span> , we need little more information about you.</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                  
                    <form>
                    <input type="text" name='amount' id="selected_plan_amount"/>
                    <input type="text" name='plan_id' id="plan_id"/>
                    
                      <div className="form-group">
                        <label for="recipient-name" className="col-form-label">Name:</label>
                        <input type="text" className="form-control"  id="customer_name" required/>
                      </div>
                      <div className="form-group">
                        <label for="message-text" className="col-form-label">Email Address:</label>
                        <input type="email" className="form-control" id="customer_email" required/>
                      </div>
                      <div className="form-group">
                        <label for="message-text" className="col-form-label">Contact Number:</label>
                        <input type="email" className="form-control" id="contact_number" required maxlength="10" pattern="/^[0-9\b]+$/" />
                      </div>
                     
                    </form>
                    
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={handleCheckout}>Register</button>
                  </div>
                </div>
              </div>
            </div>

             
        </div>  
    </>
  )
}

export default Plans